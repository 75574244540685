import React, { MouseEventHandler, useMemo } from 'react';
import { Chip, SxProps, useMediaQuery, useTheme } from '@mui/material';
import { startCase } from 'lodash';
import { ChevronDown } from 'react-feather';
import { ValidStatuses } from '../types';

export interface StatusChipProps {
  id?: string;
  fullWidth?: boolean;
  onClick?: MouseEventHandler;
  size?: 'small' | 'medium' | 'large';
  status: ValidStatuses;
  styles?: any;
  className?: string;
}

const baseStyles = {
  fontWeight: 500,
  color: 'text.primary',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  '&:focus-visible': {
    outline: '2px solid black',
  },
  '&:focus, &:active, &:hover': {
    fontWeight: 500,
  },
};

const stylesByStatus: Record<ValidStatuses, SxProps> = {
  pending: {
    bgcolor: 'primary.gray',
    '&:focus, &:active, &:hover': {
      bgcolor: 'primary.gray',
    },
  },
  lead: {
    bgcolor: 'primary.yellow',
    '&:focus, &:active, &:hover': {
      bgcolor: 'primary.yellow',
    },
  },
  qualified: {
    bgcolor: 'primary.orange',
    '&:focus, &:active, &:hover': {
      bgcolor: 'primary.orange',
    },
  },
  proposal_sent: {
    bgcolor: 'primary.purple',
    '&:focus, &:active, &:hover': {
      bgcolor: 'primary.purple',
    },
  },
  confirmed: {
    bgcolor: 'success.light',
    '&:focus, &:active, &:hover': {
      bgcolor: 'success.light',
    },
  },
  balance_due: {
    bgcolor: 'primary.pink',
    '&:focus, &:active, &:hover': {
      bgcolor: 'primary.pink',
    },
  },
  completed: {
    color: 'primary.contrastText',
    bgcolor: 'primary.main',
    '&:focus, &:active, &:hover': {
      bgcolor: 'primary.main',
    },
  },
  canceled: {
    bgcolor: 'primary.lightGray',
    '&:focus, &:active, &:hover': {
      bgcolor: 'primary.lightGray',
    },
  },
  lost: {
    bgcolor: 'primary.lightGray',
    '&:focus, &:active, &:hover': {
      bgcolor: 'primary.lightGray',
    },
  },
};

const largeStatusChipStyles = {
  padding: '8px',
  height: '40px',
  borderRadius: '46px',
};

const StatusChip = React.forwardRef<HTMLDivElement, StatusChipProps>(
  ({ id, status, fullWidth, onClick, size = 'medium', className }, ref) => {
    const statusText = useMemo(
      () => (status == 'canceled' ? startCase('lost') : startCase(status)),
      [status]
    );
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const fontSize = smallScreen ? '12px' : '14px';
    const statusColorStyles = useMemo(
      () => ({ ...stylesByStatus[status] }),
      [status]
    );

    const sx = useMemo(() => {
      const sizePropStyles = size === 'large' ? largeStatusChipStyles : {};
      return {
        ...baseStyles,
        fontSize,
        ...statusColorStyles,
        width: fullWidth ? '100%' : 'auto',
        ...sizePropStyles,
      };
    }, [fullWidth, fontSize, statusColorStyles, size]);

    const label = (
      <span
        style={{
          marginRight: onClick ? '25px' : '0',
          marginLeft: onClick ? '25px' : '0',
        }}
      >
        {statusText}
      </span>
    );

    return (
      <Chip
        id={id}
        deleteIcon={
          onClick && (
            <ChevronDown
              style={{ color: 'inherit', position: 'absolute', right: '5px' }}
              size="20"
            />
          )
        }
        label={label}
        onDelete={onClick}
        onClick={onClick}
        sx={sx}
        className={`event-status-chip ${className || ''}`}
        ref={ref}
      />
    );
  }
);

export default StatusChip;
