import React from 'react';
import { Grid } from '@mui/material';

import { Fields } from '@pv/common/components';
import validator from 'validator';

export const BillingChangeForm = ({
  creditCardEnabled,
  billingEmail,
  setBillingEmail,
  forceValidate,
  setForceValidate,
  cardholderName,
  setCardholderName,
}) => {
  // const [selectedTab, setSelectedTab] = useState(0);

  const onChangeBillingEmail = (e) => {
    setForceValidate(false);
    setBillingEmail(e.target.value);
  };

  return (
    <Grid container spacing={2} data-cy="billing-change-form">
      <Grid item xs={12}>
        <Fields.PvTextField
          required
          forceValidate={forceValidate}
          forceError={forceValidate && !validator.isEmail(billingEmail)}
          label="Billing Email"
          value={billingEmail}
          onChange={onChangeBillingEmail}
          name="billing-email"
        />
      </Grid>
      <Grid item xs={12}>
        <Fields.PvTextField
          label="Cardholder Name"
          name="cardholder_name"
          value={cardholderName}
          onChange={(e) => setCardholderName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Fields.PvStripeField />
      </Grid>
    </Grid>
  );
};
