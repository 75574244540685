import React, { useMemo } from 'react';
import { StatusCard } from './StatusCard';
import { useEventsStyles } from '../../../../events/styles';
import { activeEventStatuses, EventStatus } from '../../../../events/constants';
import { gql, useQuery } from '@apollo/client';
import { useVenues } from '../../../../providers/venues';
import { useActiveEventsContext } from '../useActiveEventsContext';

const eventsSummaryVenuesQuery = gql`
  query EventsSummaryVenues($ids: [ID!]!) {
    venuesEventsSummary(ids: $ids) {
      id
      eventsSummary
    }
  }
`;

export const StatusBar = () => {
  const { tableState, setTableState } = useActiveEventsContext();
  const status = tableState.status || EventStatus.ACTIVE;
  const classes = useEventsStyles();
  const { venueIds } = useVenues();

  const { data } = useQuery(eventsSummaryVenuesQuery, {
    variables: { ids: venueIds },
    fetchPolicy: 'cache-and-network',
    skip: !venueIds,
  });

  const eventSummaries = data?.venuesEventsSummary.map((v) => v.eventsSummary);

  // TODO - move this to the backend
  const compiledEventsSummary = useMemo(() => {
    if (eventSummaries?.length === 1) return eventSummaries[0];

    let summary = {
      counts: {
        lead: 0,
        qualified: 0,
        proposal_sent: 0,
        confirmed: 0,
        balance_due: 0,
        completed: 0,
        canceled: 0,
      },
      amounts: {
        lead: 0,
        qualified: 0,
        proposal_sent: 0,
        confirmed: 0,
        balance_due: 0,
        completed: 0,
        canceled: 0,
      },
    };

    eventSummaries?.forEach((es) => {
      summary.counts.lead += es.counts.lead;
      summary.counts.qualified += es.counts.qualified;
      summary.counts.proposal_sent += es.counts.proposal_sent;
      summary.counts.confirmed += es.counts.confirmed;
      summary.counts.balance_due += es.counts.balance_due;
      summary.counts.completed += es.counts.completed;
      summary.counts.canceled += es.counts.canceled;

      summary.amounts.lead += es.amounts.lead;
      summary.amounts.qualified += es.amounts.qualified;
      summary.amounts.proposal_sent += es.amounts.proposal_sent;
      summary.amounts.confirmed += es.amounts.confirmed;
      summary.amounts.balance_due += es.amounts.balance_due;
      summary.amounts.completed += es.amounts.completed;
      summary.amounts.canceled += es.amounts.canceled;
    });

    return summary;
  }, [eventSummaries]);

  const activeEventsAmount = () => {
    let { amounts } = compiledEventsSummary;
    let amount = 0;
    if (amounts) {
      activeEventStatuses.forEach((status) => {
        amount += amounts[status];
      });
    }
    return amount;
  };

  const activeEventsCount = () => {
    let { counts } = compiledEventsSummary;
    let count = 0;
    if (counts) {
      activeEventStatuses.forEach((status) => {
        count += counts[status];
      });
    }
    return count;
  };

  const counts = compiledEventsSummary?.counts;
  const amounts = compiledEventsSummary?.amounts;

  const setStatus = (status) => {
    setTableState((prev) => ({ ...prev, status }));
  };

  const statusCards = [
    {
      name: 'active',
      label: 'Active Events',
      count: activeEventsCount(),
      amount: activeEventsAmount(),
      status: status,
      setStatus: setStatus,
      roundLeft: true,
      roundRight: true,
    },
    {
      name: 'lead',
      label: 'Lead',
      count: counts?.lead,
      amount: amounts?.lead,
      status: status,
      setStatus: setStatus,
      arrow: true,
      roundLeft: true,
    },
    {
      name: 'qualified',
      label: 'Qualified',
      count: counts?.qualified,
      amount: amounts?.qualified,
      status: status,
      setStatus: setStatus,
      arrow: true,
    },
    {
      name: 'proposal_sent',
      label: 'Proposal Sent',
      count: counts?.proposal_sent,
      amount: amounts?.proposal_sent,
      status: status,
      setStatus: setStatus,
      arrow: true,
    },
    {
      name: 'confirmed',
      label: 'Confirmed',
      count: counts?.confirmed,
      amount: amounts?.confirmed,
      status: status,
      setStatus: setStatus,
      arrow: true,
    },
    {
      name: 'balance_due',
      label: 'Balance Due',
      count: counts?.balance_due,
      amount: amounts?.balance_due,
      status: status,
      setStatus: setStatus,
      arrow: true,
    },
    {
      name: 'completed',
      label: 'Completed YTD',
      count: counts?.completed,
      amount: amounts?.completed,
      status: status,
      setStatus: setStatus,
      roundRight: true,
    },
  ];

  return (
    <div className={classes.statusBar}>
      {statusCards.map((card, index) => (
        <StatusCard
          key={index}
          name={card.name}
          label={card.label}
          count={card.count}
          amount={card.amount}
          status={card.status}
          setStatus={card.setStatus}
          arrow={card.arrow}
          roundLeft={card.roundLeft}
          roundRight={card.roundRight}
        />
      ))}
    </div>
  );
};
