import React from 'react';
import { Box } from '@mui/material';
import { InboxEvent } from '@pv/common/graphql/types';
import { StatusColors } from '../enums';

export const InboxItemStatus = ({
  height = '48px',
  status,
}: Partial<InboxEvent> & { height?: string }) => {
  const bgColor = status ? StatusColors[status] : StatusColors.lead;
  return (
    <Box
      sx={{
        width: '6px',
        height,
        backgroundColor: bgColor,
        position: 'absolute',
        left: '0',
        borderRadius: '0 4px 4px 0',
      }}
    />
  );
};
