import { gql } from '@apollo/client';

const menuItemFields = gql`
  fragment MenuItemFields on MenuItem {
    name
    searchableName
    details
    detailsHtml
    displayOnGuestMenu
    displayOnExpressBook
    price
    unit
    image
    addons {
      id
      name
      price
    }
    choices {
      id
      name
      limit
      options {
        id
        name
        price
      }
    }
    taxes {
      id
      enabled
      name
      rate
    }
    fees {
      id
      enabled
      name
      rate
    }
    menuCategory {
      id
      name
      position
      applyTowardMinimum
      taxes {
        id
        name
        rate
      }
      fees {
        id
        name
        rate
      }
    }
  }
`;

const menuFields = gql`
  fragment MenuFields on Menu {
    name
    pdf
    menuCategories {
      id
      name
      position
      applyTowardMinimum
      salesCategory {
        id
        name
      }
      taxes {
        id
        enabled
        name
        rate
      }
      fees {
        id
        enabled
        name
        rate
      }
    }
    salesCategories {
      id
      name
    }
    menuItems {
      id
      ...MenuItemFields
    }
  }
  ${menuItemFields}
`;

const venuesQuery = gql`
  query ProviderVenues($ids: [ID!]) {
    venues(ids: $ids) {
      id
      name
      internalName
      canUpdate {
        value
      }
      canListFees {
        value
      }
      canListTaxes {
        value
      }
      canListReports {
        value
      }
      canListMessages {
        value
      }
      canListPayments {
        value
      }
      slug
      email
      image
      images {
        id
        url
        filename
        thumbnail
      }
      customerStartedAt
      banner
      description
      multiDayEnabled
      settings
      timezone
      currency
      websiteUrl
      defaultPaymentProvider
      eventStartOffset
      eventEndOffset
      defaultDepositRate
      defaultDepositAmount
      emailApiTockAddress
      emailApiTheKnotAddress
      emailApiWeddingWireAddress
      emailApiOpenTableAddress
      eventTypeOptions
      basicFeaturesEnabled
      proFeaturesEnabled
      premiumFeaturesEnabled
      achEnabled
      showPvBranding
      defaultPaymentMethodMechanism
      widgetInstanceId
      widgetContactFormRedirectUrl
      gtmId
      paymentsEnabled
      merriId
      prismmId
      featureScope {
        id
        achEnabled
        basicFeaturesEnabled
        proFeaturesEnabled
        premiumFeaturesEnabled
      }
      defaultMinimumAmount
      helloLeadFormPath
      helloLeadFormUrl
      qrcodePngUrl
      spaceGraph
      stripeAccount {
        id
        name
        stripeAccountId
      }
      squareAccount {
        id
        squareMerchantId
      }
      squareLocationId
      setup
      minGroupSize
      defaultMinimumEnabled
      defaultDiscountEnabled
      contactFormBackgroundColor
      contactFormButtonColor
      createdAt
      address {
        id
        streetAddress1
        streetAddress2
        city
        state
        zip
        country
      }
      organization {
        id
        name
        discount
        stripeCardDetail
        isCcPaymentEnabled
        billingEmail
      }
      venueMemberships {
        id
        user {
          id
          email
          firstName
          lastName
          fullName
          image
        }
      }
      spaces {
        id
        name
        seated
        standing
        ignoreConflicts
        displayColor
      }
      subscription {
        id
        planDefinitionId
        planName
        tier
        status
        billingInterval
        monthlyRate
        cardApplicationFeeRate
        achApplicationFeeRate
        stripeSourceId
        endDate
        lockOut
      }
      defaultEventOwner {
        id
      }
      expressBookSettings {
        enabled
      }
      featureFlags
    }
  }
`;

const contractFields = gql`
  fragment ContractFields on Contract {
    uuid
    totals
    discountEnabled
    discountAmount
    minimumEnabled
    minimumAmount
    depositRate
    depositAmount
    action
    signature
    locked
    lockCondition
    paymentMethodMechanism
    otherPaymentAmount
    foodAndBeverageMinimumId
    canUpdate {
      value
    }
    venuePolicy {
      id
      name
      body
    }
    contractTaxes {
      id
      rate
      enabled
      applyToMinimum
      name
      tax {
        id
        name
        rate
        enabled
        applyToMinimum
      }
    }
    contractFees {
      id
      rate
      enabled
      applyToMinimum
      name
      contractTaxes {
        id
        rate
        enabled
        name
        tax {
          id
        }
      }
      fee {
        id
        name
        rate
        enabled
        applyToMinimum
        taxes {
          id
          name
        }
      }
    }
    contractItems {
      id
      name
      index
      price
      unit
      displayOnGuestMenu
      basePrice
      quantity
      total
      details
      description
      descriptionHtml
      image
      addons {
        id
        name
        price
      }
      choices {
        id
        name
        limit
        options {
          id
          name
          price
        }
      }
      contractTaxes {
        id
        enabled
        name
        rate
        taxId
      }
      contractFees {
        id
        enabled
        name
        rate
        feeId
      }
      optionIds
      addonIds
      menuCategory {
        id
        name
        position
      }
    }
  }
`;

const eventFields = gql`
  fragment EventFields on Event {
    name
    startDate
    startOffset
    endDate
    endOffset
    tourStartDate
    tourStartOffset
    tourEndOffset
    rehearsalStartDate
    rehearsalStartOffset
    rehearsalEndOffset
    status
    eventType
    possibleEventTypes
    groupSize
    budgetOrContractAmount
    budget
    completedAt
    canceledAt
    refundedAt
    userFields
    smartFieldValues
    showProposalDeposit
    utmSource
    utmCampaign
    paymentProvider
    multiDayEnabled
    internalNoteHtml
    proposalNoteHtml
    interpolatedInternalNoteHtml
    interpolatedProposalNoteHtml
    merriId
    prismmId
    origin
    canUpdate {
      value
    }
    canShowEventBudget {
      value
    }
    user {
      id
      email
      fullName
    }
    seniorSpace {
      id
      name
      displayColor
    }
    spaces {
      id
      name
      displayColor
    }
    planner {
      id
      firstName
      lastName
      fullName
      email
      phone
      org
      eventCount
      notes
      account {
        id
        name
      }
      paymentMethods {
        id
        type
        last4
        brand
        mechanism
        displayLabel
        paymentProvider
      }
    }
    additionalContacts {
      id
      firstName
      lastName
      fullName
      email
      phone
      org
      eventCount
      notes
      account {
        id
        name
      }
    }
    paymentMethods {
      id
      type
      last4
      brand
      mechanism
      displayLabel
      paymentProvider
    }
    depositPayment {
      id
      type
      name
      kind
      amount
      refundAmount
      paidAt
      state
      amountMinusRefundAmount
      refunds {
        id
        amount
        reason
        createdAt
      }
    }
    remainingBalancePayment {
      id
      type
      name
      kind
      amount
      refundAmount
      paidAt
      state
      amountMinusRefundAmount
      refunds {
        id
        amount
        reason
        createdAt
      }
    }
    payments {
      id
      type
      name
      kind
      amount
      refundAmount
      amountMinusRefundAmount
      paidAt
      state
      refunds {
        id
        amount
        reason
        createdAt
      }
    }
    venue {
      id
      name
      internalName
      email
      image
      banner
      defaultMinimumEnabled
      merriId
      prismmId
      featureScope {
        id
        premiumFeaturesEnabled
        proFeaturesEnabled
        basicFeaturesEnabled
        achEnabled
      }
      currency
      taxes {
        id
        enabled
        name
        rate
        applyToMinimum
      }
      fees {
        id
        enabled
        name
        rate
        applyToMinimum
      }
      users {
        id
        firstName
        lastName
        fullName
        image
      }
      squareLocationId
    }
  }
`;

const eventQuery = gql`
  query Event2($id: ID!) {
    event(id: $id) {
      id
      ...EventFields
      spaceAvailability
      contract {
        id
        ...ContractFields
      }
    }
  }
  ${eventFields}
  ${contractFields}
`;

export {
  contractFields,
  eventFields,
  eventQuery,
  menuFields,
  menuItemFields,
  venuesQuery,
};
