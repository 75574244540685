import React, { useEffect, useState } from 'react';
import { Event as EventType } from '@pv/common/graphql/types';
import { ValidStatuses } from '../types';
import { EventLostDialog } from './EventLostDialog';
import { EventStatusDropDown } from './CreateEventModal/EventStatusDropDown';

interface EventStatusChipProps {
  status: ValidStatuses;
  event: EventType;
  onChange: (status: ValidStatuses) => void;
}
export const EventStatusChip = ({
  status,
  event,
  onChange,
}: EventStatusChipProps) => {
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [cancelationDialogOpen, setCancelationDialogOpen] = useState(false);

  useEffect(() => {
    setSelectedStatus(status);
  }, [status]);

  if (!event) {
    return null;
  }

  const clickStatus = (s: ValidStatuses) => {
    setSelectedStatus(s);
    if (s === 'canceled' || s === 'lost') {
      setSelectedStatus(s);
      setCancelationDialogOpen(true);
    } else {
      setSelectedStatus(s);
      onChange(s);
    }
  };

  return (
    <>
      <EventStatusDropDown status={selectedStatus} setStatus={clickStatus} />

      <EventLostDialog
        open={cancelationDialogOpen}
        onClose={() => setCancelationDialogOpen(false)}
        event={event}
        afterConfirm={() => {}}
      />
    </>
  );
};
