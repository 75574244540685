import React from 'react';
import map from 'lodash/map';

import { Menu, MenuItem } from '@mui/material';
import { usePublicConfig } from '@pv/common/providers';
import { ValidStatuses } from '../../types';

import StatusChip from '../../components/StatusChip';

interface EventStatusDropDownProps {
  status: ValidStatuses;
  setStatus: (status: ValidStatuses) => void;
}
export const EventStatusDropDown = ({
  status,
  setStatus,
}: EventStatusDropDownProps) => {
  const { publicConfig } = usePublicConfig();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const clickStatus = (s: ValidStatuses) => () => {
    setStatus(s);
    handleClose();
  };

  const statuses = publicConfig.validEventStatuses.filter(
    (s: ValidStatuses) => !['canceled', 'pending'].includes(s)
  );
  return (
    <>
      <StatusChip
        ref={anchorRef}
        id={`event-status-chip`}
        status={status}
        onClick={handleClick}
        fullWidth
        size="large"
        aria-controls={isOpen ? 'event-status-chip-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : undefined}
      />

      <Menu
        id="event-status-chip-menu"
        anchorEl={anchorRef.current}
        open={isOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'event-status-chip',
        }}
        slotProps={{
          paper: {
            style: {
              minWidth: anchorRef.current
                ? anchorRef.current.clientWidth
                : undefined,
            },
          },
        }}
      >
        {map(statuses, (s) => (
          <MenuItem
            className="event-status-option"
            onClick={clickStatus(s)}
            key={s}
            selected={s === status}
          >
            <StatusChip status={s} fullWidth size="large"></StatusChip>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default EventStatusDropDown;
