import { Button, Toolbar, useMediaQuery } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import compact from 'lodash/compact';
import size from 'lodash/size';
import invert from 'lodash/invert';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Fields } from '@pv/common/components';
import { useVenues } from '../../../providers/venues';
import {
  BarChart2,
  CreditCard,
  DollarSign,
  FileText,
  List,
  Users,
} from 'react-feather';

const secondaryAppbarHeight = 42;

export const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    zIndex: 2,
    position: 'relative',
  },
  secondaryAppBar: {
    height: secondaryAppbarHeight,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  secondaryTab: {
    color: 'rgba(59, 57, 108, 0.6)',
    textTransform: 'none',
    height: secondaryAppbarHeight,
    borderRadius: 0,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    borderTop: `2px solid transparent`,
    borderBottom: `2px solid transparent`,
    padding: '9px 16px',
    gap: '6px',
  },
  selectedSecondaryTab: {
    color: 'rgba(39, 43, 100, 1)',
    borderBottom: `2px solid rgba(39, 43, 100, 1)`,
  },
  secondaryToolBar: {
    height: secondaryAppbarHeight,
    minHeight: secondaryAppbarHeight,
    padding: '0px 20px',
    gap: '12px',
    backgroundColor: 'white',
  },
}));

const Tab = ({ children, className, href, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const clsxInput = {
    [className]: true,
    [classes.secondaryTab]: true,
    [classes.selectedSecondaryTab]: pathname === href,
  };

  const onClick = () => {
    navigate(href);
  };

  return (
    <Button className={clsx(clsxInput)} onClick={onClick} {...rest}>
      {children}
    </Button>
  );
};

const SecondaryAppBar = () => {
  const { venues } = useVenues();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  let buttons;
  let rightButton;
  let mobileVals;

  mobileVals = [
    'Analytics',
    'Events',
    'Payments',
    'Proposals',
    'Contacts',
    'Accounts',
    'Sales',
  ];
  buttons = compact([
    size(venues) === 1 && (
      <Tab
        key="reports_analytics"
        href="/reports/analytics"
        startIcon={<BarChart2 size={20} />}
      >
        Analytics
      </Tab>
    ),
    <Tab
      key="reports_events"
      href="/reports/events"
      startIcon={<List size={20} />}
    >
      Events
    </Tab>,
    <Tab
      key="reports_payments"
      href="/reports/payments"
      startIcon={<CreditCard size={20} />}
    >
      Payments
    </Tab>,
    <Tab
      key="reports_proposal"
      href="/reports/proposal_data"
      startIcon={<FileText size={20} />}
    >
      Proposals
    </Tab>,
    <Tab
      key="reports_contacts"
      href="/reports/contacts"
      startIcon={
        <img
          src="/contacts.png"
          alt="report contacts"
          style={{ width: 20, height: 20 }}
        />
      }
    >
      Contacts
    </Tab>,
    <Tab
      key="reports_accounts"
      href="/reports/accounts"
      startIcon={<Users size={20} />}
    >
      Accounts
    </Tab>,
    <Tab
      key="reports_sales"
      href="/reports/sales"
      startIcon={<DollarSign size={20} />}
    >
      Sales
    </Tab>,
  ]);

  const paths = {
    '/reports/analytics': 'Analytics',
    '/reports/accounts': 'Accounts',
    '/reports/events': 'All Event Data',
    '/reports/payments': 'All Payment Data',
    '/reports/proposal_data': 'All Proposal Data',
  };
  const invertedPath = invert(paths);
  const { pathname } = location;
  const navValue = paths[pathname];

  const onMobileNavChange = (e) => {
    navigate(invertedPath[e.target.value]);
  };

  if (smallScreen) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Fields.PvDropDown
          value={navValue}
          onChange={onMobileNavChange}
          vals={mobileVals}
        />
      </div>
    );
  }
  return (
    <Toolbar classes={{ root: classes.secondaryToolBar }}>
      {compact(buttons)}
      <div style={{ flexGrow: 1 }} />
      {rightButton}
    </Toolbar>
  );
};

export default SecondaryAppBar;
