import { Typography } from '@mui/material';
import { useTheme } from '@material-ui/styles';
import clsx from 'clsx';
import snakeCase from 'lodash/snakeCase';
import replace from 'lodash/replace';
import round from 'lodash/round';
import React, { useEffect, useState } from 'react';
import { moneyPrint } from '@pv/common/utils';
import { useEventsStyles } from '../../../../events/styles';
import {
  EventStatusColors,
  PVGray,
  PVHoverNavy,
  PVNavy,
} from '@pv/common/colors';
import { EventStatus } from '../../../../events/constants';

export const StatusCard = ({
  name,
  label,
  count = 0,
  amount = 0,
  status = EventStatus.ACTIVE,
  setStatus,
  roundLeft,
  roundRight,
  arrow,
}) => {
  const classes = useEventsStyles();
  const theme = useTheme();
  const [hoverState, setHoverState] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState();

  const active = name === status;

  useEffect(() => {
    let color = 'white';
    if (active && label === EventStatus.ACTIVE) {
      color = PVNavy;
    } else if (active) {
      color = EventStatusColors[snakeCase(label)] || PVGray;
    } else if (hoverState) {
      color = PVHoverNavy;
    }
    setBackgroundColor(color);
  }, [active, hoverState, label]);

  const canShowEventsSummaryAmounts = true;

  const radius = theme.shape.borderRadius * 2;

  const getPillTextColor = (label) => {
    if (label === EventStatus.COMPLETED) {
      return theme.palette.common.white;
    } else {
      return theme.palette.text.primary;
    }
  };

  const pillColor = getPillTextColor(label);

  const getLabelColor = (label) => {
    return active &&
      (label === EventStatus.COMPLETED || label === EventStatus.ACTIVE)
      ? theme.palette.common.white
      : theme.palette.text.primary;
  };

  const getAmountColor = (label) => {
    return active &&
      (label === EventStatus.COMPLETED || label === EventStatus.ACTIVE)
      ? theme.palette.common.white
      : theme.palette.primary.main;
  };

  return (
    <div
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      style={{
        marginRight: label === EventStatus.ACTIVE ? 16 : 0,
      }}
      className={clsx({
        [`status-card-${snakeCase(name || label)}`]: true,
        [classes.statusCard]: true,
      })}
      onClick={() => setStatus(name)}
    >
      <div
        style={{
          borderRight: arrow ? 'none' : undefined,
          borderTopLeftRadius: roundLeft ? radius : undefined,
          borderBottomLeftRadius: roundLeft ? radius : undefined,
          borderTopRightRadius: roundRight * 2 ? radius : undefined,
          borderBottomRightRadius: roundRight * 2 ? radius : undefined,
          backgroundColor: backgroundColor,
        }}
        className={clsx({
          [classes.statusBox]: true,
          [classes.roundedLeftStatusBox]:
            label === EventStatus.ACTIVE || label === EventStatus.LEAD,
        })}
      >
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                marginRight: 6,
              }}
            >
              <Typography
                variant="h5"
                classes={{
                  root: clsx({
                    [classes.statusPill]: true,
                  }),
                }}
                style={{
                  fontSize: 15,
                  backgroundColor: active
                    ? theme.palette.common.white
                    : EventStatusColors[snakeCase(label)] || PVGray,
                  color: active ? theme.palette.primary.main : pillColor,
                }}
              >
                {count}
              </Typography>
            </div>
            <div>
              <Typography
                variant="h5"
                classes={{
                  root: clsx({
                    [classes.activeText]: active,
                  }),
                }}
                style={{
                  fontSize: 15,
                  fontWeight: 500,
                  color: getLabelColor(label),
                }}
              >
                {label}
              </Typography>
            </div>
          </div>

          {canShowEventsSummaryAmounts && (
            <div
              style={{
                marginTop: 2,
              }}
            >
              <Typography
                variant="h4"
                classes={{
                  root: clsx({
                    [classes.statusCardLabel]: !active,
                    [classes.activeText]: active,
                  }),
                }}
                align="left"
                style={{
                  fontSize: 22,
                  color: getAmountColor(label),
                }}
              >
                {amount
                  ? replace(moneyPrint(round(amount / 100)), /\.\d\d/, '')
                  : '$0'}
              </Typography>
            </div>
          )}
        </div>
      </div>
      {arrow && (
        <div
          className={clsx({
            [classes.arrow]: true,
            [classes.activeArrow]: active,
          })}
        >
          <div
            className={clsx({
              [classes.innerArrow]: true,
            })}
            style={{
              borderLeftColor: backgroundColor,
            }}
          />
        </div>
      )}
    </div>
  );
};
